const generateErrorObj = (message: string, code: number = 422) => {
  const error = new Error(message);
  error.code = code;
  return error;
};

/* eslint-disable no-undef */
function onGotAllSlices(docDataSlices, resolve) {
  // Combine the docDataSlices arrays into a single array
  const docData = docDataSlices.reduce((acc, slice) => acc.concat(slice), []);

  // Convert the docData array to a Uint8Array
  const uint8Array = new Uint8Array(docData);

  // Convert the Uint8Array to a string
  const fileContent = uint8Array.reduce((content, byte) => content + String.fromCharCode(byte), "");

  // Convert the fileContent to base64 format
  const base64Data = btoa(fileContent);

  // Join base64 url
  const fullUri = `data:application/pdf;base64,${base64Data}`;

  resolve(fullUri);
}

function getSliceAsync(file, nextSlice, sliceCount, gotAllSlices, docDataSlices, slicesReceived, resolve, reject) {
  file.getSliceAsync(nextSlice, function (sliceResult) {
    if (sliceResult.status == "succeeded") {
      if (!gotAllSlices) {
        /* Failed to get all slices, no need to continue. */
        return;
      }

      // Got one slice, store it in a temporary array.
      // (Or you can do something else, such as
      // send it to a third-party server.)
      docDataSlices[sliceResult.value.index] = sliceResult.value.data;
      if (++slicesReceived == sliceCount) {
        // All slices have been received.
        file.closeAsync();
        onGotAllSlices(docDataSlices, resolve);
      } else {
        getSliceAsync(file, ++nextSlice, sliceCount, gotAllSlices, docDataSlices, slicesReceived, resolve, reject);
      }
    } else {
      gotAllSlices = false;
      file.closeAsync();
      reject(generateErrorObj("File size exceeds the maximum limit. (4mb)"));
    }
  });
}

export async function getDocumentBase64() {
  return new Promise((resolve, reject) => {
    Word.run(async () => {
      Office.context.document.getFileAsync(Office.FileType.Pdf, function (result) {
        if (result.status == Office.AsyncResultStatus.Succeeded) {
          const myFile = result.value;
          const sliceCount = myFile.sliceCount;

          // Get the file slices.
          const docDataSlices = [];
          let slicesReceived = 0,
            gotAllSlices = true;

          getSliceAsync(myFile, 0, sliceCount, gotAllSlices, docDataSlices, slicesReceived, resolve, reject);

          // Resolve the promise with the result from onGotAllSlices
          myFile.closeAsync();
        } else {
          reject(generateErrorObj("Failed to processing document"));
        }
      });
    });
  });
}

export const getDocumentFilename = () => {
  const documentName = Office.context.document.url.split("/").pop();
  return documentName.split(".")?.[0];
};
